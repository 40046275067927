import gql from 'graphql-tag'

const userFragment = gql`
  fragment UserContents on BasicUserType {
    id
    firstName
    lastName
    patronym
    isSuperuser
    login
    fullName
    shortName
    isActive
  }
`

const pagedUsers = gql`
  query($paged: PagedInputType!, $filters: UserFiltersType!) {
    usersPage(paged: $paged, filters: $filters) {
      hasNext
      totalCount
      users {
        ...UserContents
      }
    }
  }
  ${userFragment}
`

const currentUser = gql`
  {
    currentUser {
      ...UserContents
    }
    currentUserPermissions
    commonComputerStatus
  }
  ${userFragment}
`

export { currentUser, pagedUsers, userFragment }
