import gql from 'graphql-tag'
import { getErrors } from './query'

const typeDefs = gql`
  type ErrorType {
    id: String!
    text: String!
    show: Boolean!
  }
`
const resolvers = {
  Mutation: {
    pushError(_, { error }, { cache }) {
      const data = cache.readQuery({ query: getErrors })
      error.__typename = 'ErrorType'
      data.errors.push(error)
      cache.writeQuery({ query: getErrors, data })
      setTimeout(() => {
        const data = cache.readQuery({ query: getErrors })
        const shownError = data.errors.find(e => e.id === error.id)
        shownError.show = false
        cache.writeQuery({ query: getErrors, data })
      }, 5000)
      return error
    },
    hideError(_, { id }, { cache }) {
      const data = cache.readQuery({ query: getErrors })
      const error = data.errors.find(e => e.id === id)
      error.show = false
      cache.writeQuery({ query: getErrors, data })
    }
  }
}
export { typeDefs, resolvers }
