import gql from 'graphql-tag'

const pushError = gql`
  mutation($error: ErrorType!) {
    pushError(error: $error) @client {
      id
    }
  }
`

const hideError = gql`
  mutation($id: Int!) {
    hideError(id: $id) @client {
      id
    }
  }
`

export { pushError, hideError }
