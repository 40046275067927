import { setContext } from 'apollo-link-context'

export const middleware = ctx => {
  /**
   * По-умолчанию при рендере со стороны сервера заголовки не отправляются.
   * Добавление заголовков от клиента.
   */
  const ssrMiddleware = setContext((_, { headers }) => {
    if (process.client) return headers
    return {
      headers: {
        ...headers,
        connection: ctx.app.context.req.headers.connection,
        referer: ctx.app.context.req.headers.referer,
        cookie: ctx.app.context.req.headers.cookie
      }
    }
  })

  // Добавление CSRF-токена к запросу
  const csrfMiddleware = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-CSRFToken': ctx.app.$cookies.get('csrftoken') || null
      }
    }
  })

  return {
    ssrMiddleware,
    csrfMiddleware
  }
}
