import Vue from 'vue'
import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import cache from '../plugins/apollo-client-configs/build-cache'
import { httpLink } from '../plugins/apollo-client-configs/build-http-link'
import { currentUser } from '../components/users/users/query'

const auth = new Vue({
  data: {
    client: null,
    showLoginDialog: false,
    user: {
      id: 0,
      shortName: '',
      isSuperuser: false,
      permissions: [],
      realPermissions: [],
      commonComputer: false,
      isSalesManager: false,
      companyIDs: [],
      loggedIn: false
    }
  },
  created() {
    if (process.client) {
      // костыль для отображения имени на фронте
      // TODO переписать auth как глобальный vue mixin
      this.client = new ApolloClient({ link: httpLink, cache })
      this.loggedIn()
    }
  },
  methods: {
    async loggedIn() {
      try {
        const { data } = await this.client.query({
          query: currentUser
        })
        if (data.currentUser) {
          this.user.id = data.currentUser.id
          this.user.isSuperuser = data.currentUser.isSuperuser
          this.user.shortName = data.currentUser.shortName
          this.user.permissions = data.currentUserPermissions
          this.user.commonComputer = data.commonComputerStatus
        }
        this.user.loggedIn = true
        return data.currentUser !== null
      } catch (e) {
        this.user.loggedIn = false
        return false
      }
    },
    async login(name, password, commonComputer) {
      try {
        const { data } = await this.client.mutate({
          mutation: gql`
            mutation($params: LoginInput!) {
              login(input: $params) {
                success
                user {
                  id
                  shortName
                  isSuperuser
                }
              }
            }
          `,
          variables: {
            params: {
              login: name,
              password: password,
              commonComputer: commonComputer
            }
          }
        })
        if (data.login.success) {
          this.user.id = data.login.user.id
          this.user.isSuperuser = data.login.user.isSuperuser
          this.user.shortName = data.login.user.shortName
          const { data: perms } = await this.client.query({
            fetchPolicy: 'network-only',
            query: gql`
              {
                currentUserPermissions
              }
            `
          })
          this.user.permissions = perms.currentUserPermissions
          const { data: commonComputer } = await this.client.query({
            fetchPolicy: 'network-only',
            query: gql`
              {
                commonComputerStatus
              }
            `
          })
          this.user.commonComputer = commonComputer.commonComputerStatus
          this.user.loggedIn = true
        } else {
          this.resetUser()
        }
        return data.login.success
      } catch (e) {
        return false
      }
    },
    async getPermissions() {
      const loggedIn = await this.loggedIn()
      if (loggedIn) {
        try {
          const { data } = await this.client.query({
            fetchPolicy: 'network-only',
            query: gql`
              {
                currentUserPermissions
              }
            `
          })
          this.user.permissions = data.currentUserPermissions
          return data.currentUserPermissions
        } catch (e) {
          return []
        }
      } else {
        return []
      }
    },
    hasPermission(permName) {
      if (this.user.isSuperuser) {
        return true
      } else {
        return this.user.permissions.indexOf(permName) > -1
      }
    },
    async logout() {
      if (this.loggedIn()) {
        try {
          const { data } = await this.client.mutate({
            mutation: gql`
              mutation {
                logout {
                  success
                }
              }
            `
          })
          if (data.logout.success) {
            this.resetUser()
          }
          return data.logout.success
        } catch (e) {
          return false
        }
      } else {
        return false
      }
    },
    resetUser() {
      this.user.id = 0
      this.user.shortName = ''
      this.user.isSuperuser = false
      this.user.permissions = []
      this.user.commonComputer = false
      this.user.loggedIn = false
    }
  }
})

export default auth
