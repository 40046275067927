<template>
  <v-app>
    <client-only>
      <navigation v-model="menuDrawer" />
    </client-only>
    <div>
      <top-menu v-if="showMenu" @open-menu="menuDrawer = true" />
      <alarms ref="alarms" />
      <client-only>
        <notifications
          group="commonNotification"
          :max="1"
          position="top right"
          width="450px"
        >
          <template slot="body" slot-scope="props">
            <div
              class="mb-1 common-notification"
              :class="{
                error: !!(props.item.data && props.item.data.error),
                warning: !!(props.item.data && props.item.data.warning),
                success: !!(props.item.data && props.item.data.success)
              }"
            >
              <table width="100%">
                <tr>
                  <td class="subtitle-1">
                    <span v-if="!(props.item.data && props.item.data.error)"
                      >Уведомление</span
                    >
                    <span v-if="!!(props.item.data && props.item.data.error)"
                      >Ошибка</span
                    >
                  </td>
                  <td style="width: 30px">
                    <a
                      class="close"
                      title="Закрыть"
                      @click.stop="props.close()"
                    >
                      <v-icon color="white">close</v-icon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div
                      class="mt-2"
                      style="word-break: break-all"
                      v-html="props.item.text"
                    ></div>
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </notifications>
      </client-only>
      <nuxt />
    </div>
  </v-app>
</template>

<script>
import TopMenu from '../components/menu/TopMenu'
import Alarms from '../components/users/Alarms'
import Navigation from '../components/menu/Navigation'

export default {
  name: 'App',
  components: {
    Navigation,
    TopMenu,
    Alarms
  },
  data() {
    return {
      menuDrawer: false
    }
  },
  computed: {
    showMenu: function() {
      return this.$route.name !== 'login'
    }
  },
  // При обновлении проекта удаляем все сохраненные в браузере данные
  mounted() {
    // Находим подключенный скрипт /_nuxt/xxx.js и достаем значение xxx (hash)
    const scripts = document.getElementsByTagName('script')
    const lastScript = scripts[scripts.length-1]
    const src = lastScript.src
    if (src.match(/\S*\/_nuxt\/\S*js$/g)) {
      const buildHash = src.replace(/(\S*\/)|(.js$)/g, '')
      if (buildHash !== localStorage.buildHash) {
        Object.keys(localStorage).forEach(key => {
          delete localStorage[key]
        })
        localStorage.buildHash = buildHash
      }
    }
  }
}
</script>

<style lang="stylus">
html {
  overflow-y: auto !important;
}

tr.locked, tr.deleted, tr.fired, tr.checked, tr.new, tr.outgoing-invoice {
  border-bottom: 1px solid rgba(0, 0, 0, .12) !important;
}

tr.locked:hover, tr.deleted:hover, tr.fired:hover, tr.checked:hover, tr.new:hover, tr.outgoing-invoice:hover, tr.success:hover {
  background-color: rgba(0, 0, 0, .06) !important;
}

.common-notification {
  padding: 10px;
  margin: 5px;

  color: #ffffff;
  background: rgba(33, 33, 33, .8);
  border-left: 5px solid #212121;
}

.common-notification.error {
  background: rgba(183, 28, 28, .8) !important;
  border-left: 5px solid #b71c1c;
}

.common-notification.warning {
  background: rgba(168, 184, 0, .8) !important;
  border-left: 5px solid #b71c1c;
}

.common-notification.success {
  background: rgba(0, 179, 0, .8) !important;
  border-left: 5px solid #b71c1c;
}

.no-select-text {
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.displayNone {
  display: none !important;
}
</style>
