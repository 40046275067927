<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <slot v-bind="on" name="activator" />
    </template>
    <v-card>
      <v-card-title class="headline grey lighten-2 py-3 pr-3">
        Смена пароля
        <v-spacer />
        <v-btn @click="dialog = false" :disabled="loading" icon small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs10 offset-xs1>
              <v-text-field
                v-model="newPassword"
                :rules="passwordRule"
                name="newPassword"
                label="Новый пароль*"
                required
                type="password"
              />
            </v-flex>
            <v-flex xs10 offset-xs1>
              <v-text-field
                v-model="confirmation"
                :rules="passwordRule"
                label="Повторите новый пароль*"
                required
                type="password"
                name="confirmation"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <span v-if="!passwordsMatch" class="subtitle-1 ml-3 error--text"
          >Пароли не совпадают</span
        >
        <v-spacer />
        <v-btn
          @click.native="dialog = false"
          :disabled="loading"
          color="blue darken-1"
          text
        >
          Закрыть
        </v-btn>
        <v-btn
          @click.native="save"
          :disabled="!passwordsMatch"
          :loading="loading"
          color="blue darken-1"
          text
          >Сменить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { changeUserPassword } from './query'

export default {
  name: 'UserPasswordDialog',
  props: {
    own: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userId: null,
      dialog: false,
      newPassword: '',
      confirmation: '',
      passwordRule: [
        text => !!text || 'Пароль не может быть пустым',
        text => text.length >= 8 || 'Минимум 8 символов'
      ],
      loading: false
    }
  },
  computed: {
    passwordsMatch() {
      return this.newPassword === this.confirmation && this.newPassword !== ''
    }
  },
  methods: {
    open(userId) {
      this.userId = userId
      this.dialog = true
    },
    save() {
      if (this.own) {
        this.dialog = false
        this.$emit('save', this.newPassword)
        return
      }
      this.loading = true
      this.$apollo
        .mutate({
          mutation: changeUserPassword,
          variables: {
            input: {
              userId: this.userId,
              password: this.newPassword
            }
          }
        })
        .then(({ data }) => {
          this.dialog = false
          if (data.changeUserPassword.success) {
            this.$notify({
              group: 'commonNotification',
              duration: 5000,
              text: 'Пароль успешно сменён'
            })
          }
        })
        .catch(e => {
          this.dialog = false
          this.loading = false
          throw new Error(e)
        })
    }
  }
}
</script>
