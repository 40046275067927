<template>
  <v-tooltip bottom style="max-width: 42px" fixed>
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-icon
          :color="color"
          :size="size"
          v-on="on"
          class="inline-help-pointer"
        >
          {{ icon }}
        </v-icon>
      </slot>
    </template>
    <div
      :class="{
        'text-left': textAlign === 'left',
        'text-center': textAlign === 'center',
        'text-right': textAlign === 'right'
      }"
      :style="{
        'max-width': maxWidth
      }"
    >
      <slot></slot>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'InlineHelp',
  props: {
    icon: {
      type: String,
      default: 'help'
    },
    color: {
      type: String,
      default: 'info'
    },
    textAlign: {
      type: String,
      default: 'center'
    },
    maxWidth: {
      type: String,
      default: '300px'
    },
    size: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.inline-help-pointer {
  cursor: pointer;
}
</style>
