import { from, split, concat } from 'apollo-link'
import { ApolloClient } from 'apollo-client'
import { isSubscription } from '../../utils'
import { typeDefs, resolvers } from '../../graphql/local-schema/typeDefs'
import auth from '../../utils/auth'
import cache from './build-cache'
import { errorLink as errorHandler } from './error-handler'
import { middleware } from './middleware'
import { httpLink as builtHttpLink } from './build-http-link'
// import { wsLink } from './build-ws-link'
import { uploadLink } from './build-upload-link'

export default ctx => {
  const { ssrMiddleware, csrfMiddleware } = middleware(ctx)
  const errorLink = errorHandler(ctx)

  // Если загружается файл, то используем uploadLink
  let httpLink = split(
    operation => operation.getContext().hasUpload,
    uploadLink,
    builtHttpLink
  )

  // Если тип операции subscription, то используем wsLink
  // httpLink = split(isSubscription, wsLink, httpLink)

  const middlewares = from([csrfMiddleware, ssrMiddleware])

  // Если тип операции subscription не используем middleware
  let link = split(
    isSubscription,
    concat(csrfMiddleware, httpLink),
    concat(middlewares, httpLink)
  )
  link = errorLink.concat(link)

  auth.client = new ApolloClient({ link, cache })
  // Create the apollo client
  return {
    link,
    cache,
    defaultHttpLink: false,
    typeDefs,
    resolvers,
    // Set this on the server to optimize queries when SSR or temporary disable query force-fetching
    ...(process.server ? { ssrMode: true } : { ssrForceFetchDelay: 100 })
  }
}
