<template>
  <v-card color="grey lighten-4" raised class="py-4 px-5">
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <div class="display-1 mb-4 text-center">
        Система контроля станциями приема жидких бытовых отходов (ЖБО)
      </div>
      <v-text-field
        v-model="login"
        :rules="loginRules"
        @blur="clearLogin"
        label="Логин*"
        required
        name="login_field"
      />
      <v-text-field
        v-model="password"
        :rules="passRules"
        :append-icon="passwordVisible ? 'visibility' : 'visibility_off'"
        :type="passwordVisible ? 'password' : 'text'"
        @click:append="() => (passwordVisible = !passwordVisible)"
        label="Пароль*"
        required
        name="password_field"
      />

      <div style="display: flex; justify-content: center">
        <v-btn
          :disabled="loading"
          :loading="loading"
          type="submit"
          name="login_btn"
          color="submit"
          nuxt
          >Войти</v-btn
        >
      </div>
    </v-form>
  </v-card>
</template>

<script>
import logo from '../../../assets/logo2.0.png'
import auth from '../../../utils/auth'
import InlineHelp from '../../common/InlineHelp'

export default {
  name: 'login-dialog',
  metaInfo: {
    title: 'Вход для сотрудников ООО "Синергия"'
  },
  components: {
    InlineHelp
  },
  methods: {
    clearLogin() {
      let val = this.login
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(val)) {
        val = val.toLowerCase()
      }
      val = val.replace(/\s+/g, '')
      this.login = val
    },
    submit() {
      this.loading = true
      auth
        .login(this.login, this.password, this.commonComputer)
        .then(success => {
          this.requestFail = !success
          if (success) {
            auth.showLoginDialog = false
            this.$apollo.provider.defaultClient.resetStore().then(() => {
              let redirect = this.$route.query.redirect
              if (!redirect) {
                redirect = { path: '/' }
              }
              this.$emit('loginSuccess')
              if (this.$route.name === 'login') {
                this.$router.replace(redirect)
              }
            })
          } else {
            this.$refs.form.validate()
            this.requestFail = false
          }
          this.loading = false
        })
    }
  },
  data() {
    return {
      logo: logo,
      login: '',
      password: '',
      commonComputer: false,
      valid: false,
      requestFail: false,
      passwordVisible: true,
      loading: false,
      loginRules: [
        v => !!v || 'Необходимо ввести логин',
        () => (this.requestFail ? 'Проверьте логин' : true)
      ],
      passRules: [
        v => !!v || 'Необходимо ввести пароль',
        () => (this.requestFail ? 'Проверьте пароль' : true)
      ]
    }
  }
}
</script>
