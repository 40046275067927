<template>
  <v-navigation-drawer v-model="innerValue" temporary absolute width="400">
    <v-list style="border-bottom: 1px solid grey">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ auth.user.shortName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list>
      <template v-for="topItem in menuItems">
        <v-list-group
          v-if="topItem.subItems.length > 0"
          v-model="topItem.expanded"
          no-action
        >
          <v-list-item slot="activator">
            <v-list-item-title>{{ topItem.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="item in topItem.subItems"
            :key="item.label"
            :to="item.link"
            exact
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import gql from 'graphql-tag'
import utilsMixin from '../../utils/utils'
import auth from '../../utils/auth'

export default {
  name: 'Navigation',
  mixins: [utilsMixin],
  props: {
    value: Boolean
  },
  apollo: {
    menuItems: {
      query: gql`
        {
          menuItems: menu {
            name: text
            subItems: items {
              link
              label: text
            }
          }
        }
      `,
      update({ menuItems }) {
        if (menuItems) {
          const mutableItems = JSON.parse(JSON.stringify(menuItems))
          mutableItems.forEach(item => {
            item.expanded = false
            return item
          })
          return mutableItems
        }
      },
      skip() {
        // Не запрашиваем меню если мы знаем, что не залогинены
        return auth.user.id < 1
      }
    }
  },
  data() {
    return {
      menuItems: [],
      openAvatarUpdate: false,
      auth: auth
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>
