<template>
  <v-toolbar dense color="toolbar" class="toolbar-menu">
    <v-btn @click="$emit('open-menu')" text title="Меню" nuxt>
      <v-icon class="mr-2">menu</v-icon>
      Меню
    </v-btn>
    <v-spacer />
    <span class="subheader black--text px-4">
      <b>{{ greeting }}</b>
    </span>

    <user-password-dialog @save="changePassword" own>
      <template v-slot:activator="click">
        <v-btn v-on="click" icon small title="Сменить пароль" class="mx-2">
          <v-icon>mdi-lock-reset</v-icon>
        </v-btn>
      </template>
    </user-password-dialog>
    <v-btn
      to="/logout"
      icon
      small
      title="Выход"
      name="exit_btn"
      class="mx-2"
      nuxt
    >
      <v-icon>exit_to_app</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import gql from 'graphql-tag'
import auth from '../../utils/auth'
import UserPasswordDialog from '../users/user/PasswordDialog'

export default {
  name: 'TopMenu',
  components: {
    UserPasswordDialog
  },
  data() {
    return {
      user: auth.user
    }
  },
  computed: {
    greeting() {
      if (auth.user.id < 1) {
        return ''
      } else {
        return 'Вы вошли как ' + auth.user.shortName
      }
    }
  },
  methods: {
    changePassword(password) {
      this.$apollo.mutate({
        mutation: gql`
          mutation($input: ChangeOwnPasswordInput!) {
            changeOwnPassword(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            password: password
          }
        }
      })
    },
    goToDoc() {
      const PRODUCTION = process.env.NODE_ENV === 'production'
      const DEVELOPMENT = process.env.NODE_ENV === 'development'
      let path
      if (PRODUCTION) {
        path = `${window.location.hostname}:${window.location.port}/docs${this.$route.path}`
        path =
          location.protocol === 'https:' ? 'https://' + path : 'http://' + path
      } else if (DEVELOPMENT) {
        path = `http://localhost:8080/docs${this.$route.path}`
      }
      // if (this.$route.path === '/') {
      //   path += 'reports'
      // }
      if (this.$route.name.indexOf('-id') !== -1) {
        const pathArr = path.split('/')
        path = pathArr.slice(0, pathArr.length - 1).join('/')
      }
      path = path.replace(/^\/+|\/+$/g, '') + '.html'
      if (DEVELOPMENT) {
        this.openDocs(path)
        return
      }
      this.$axios
        .get(path)
        .then(() => {
          this.openDocs(path)
        })
        .catch(e => {
          if (e.response && e.response.status === 404) {
            this.$refs.help.dialog = true
            return
          }
          throw new Error(e)
        })
    },
    openDocs(path) {
      const win = window.open(path, '_blank')
      win.focus()
    }
  }
}
</script>

<style>
.toolbar-menu .v-toolbar__content {
  padding-left: 7px;
}
</style>
