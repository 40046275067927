var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{staticStyle:{"max-width":"42px"},attrs:{"bottom":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",[_c('v-icon',_vm._g({staticClass:"inline-help-pointer",attrs:{"color":_vm.color,"size":_vm.size}},on),[_vm._v("\n        "+_vm._s(_vm.icon)+"\n      ")])],null,{ on: on })]}}],null,true)},[_vm._v(" "),_c('div',{class:{
      'text-left': _vm.textAlign === 'left',
      'text-center': _vm.textAlign === 'center',
      'text-right': _vm.textAlign === 'right'
    },style:({
      'max-width': _vm.maxWidth
    })},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }