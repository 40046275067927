import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cb0e9c02 = () => interopDefault(import('../pages/clients.vue' /* webpackChunkName: "pages/clients" */))
const _19e22550 = () => interopDefault(import('../pages/contracts.vue' /* webpackChunkName: "pages/contracts" */))
const _8e6962c0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a263f276 = () => interopDefault(import('../pages/operations.vue' /* webpackChunkName: "pages/operations" */))
const _1695b93a = () => interopDefault(import('../pages/print_page.vue' /* webpackChunkName: "pages/print_page" */))
const _7602cc3f = () => interopDefault(import('../pages/stations_monitoring.vue' /* webpackChunkName: "pages/stations_monitoring" */))
const _348d85bf = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _21b8a8ee = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/clients",
    component: _cb0e9c02,
    name: "clients"
  }, {
    path: "/contracts",
    component: _19e22550,
    name: "contracts"
  }, {
    path: "/login",
    component: _8e6962c0,
    name: "login"
  }, {
    path: "/operations",
    component: _a263f276,
    name: "operations"
  }, {
    path: "/print_page",
    component: _1695b93a,
    name: "print_page"
  }, {
    path: "/stations_monitoring",
    component: _7602cc3f,
    name: "stations_monitoring"
  }, {
    path: "/users",
    component: _348d85bf,
    name: "users"
  }, {
    path: "/",
    component: _21b8a8ee,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
