import { onError } from 'apollo-link-error'
import { pushError } from '../../graphql/local-schema/mutation'
import { getErrors } from '../../graphql/local-schema/query'
import { generateUid } from '../../utils'
import auth from '../../utils/auth'

export const errorLink = ctx =>
  onError(({ graphQLErrors }) => {
    if (!graphQLErrors || graphQLErrors.length === 0) return
    let text = graphQLErrors[0].message
    if (text === '') {
      return
    }
    if (text.indexOf('matching query does not exist') > 0) {
      ctx.error('Страница не найдена')
      return
    }
    if (text.indexOf('::') > -1) {
      const split = text.split('::', 2)
      text = split[1] || ''
      const code = parseInt(split[0])
      if (code === 401 && ctx.route.path && ctx.route.path !== '/login') {
        // Необходим логин
        auth.showLoginDialog = true
        const currentRoute = ctx.route.path
        if (process.client) {
          ctx.next({
            path: '/login',
            query: { redirect: currentRoute }
          })
        } else {
          ctx.redirect('/login')
        }
        return
      }
      if (code === 401) return
      if (ctx.route.path === '/') {
        return
      }
    }
    const { errors } = ctx.app.apolloProvider.defaultClient.readQuery({
      query: getErrors
    })
    const length = errors ? errors.length : 0
    const last = length > 0 ? errors[length - 1] : null
    if (last && last.text === text && last.show) {
      return
    }
    ctx.app.apolloProvider.defaultClient.mutate({
      mutation: pushError,
      variables: {
        error: {
          id: generateUid(),
          text,
          show: true
        }
      },
      update(store) {
        const data = store.readQuery({ query: getErrors })
        if (data.errors.length > 5) {
          setTimeout(() => {
            data.errors.shift()
            store.writeQuery({ query: getErrors, data })
          }, 5000)
        }
      }
    })
    if (ctx.store.state.errors && ctx.store.state.errors.length > 5) {
      ctx.store.commit('shiftError')
    }
  })
