<template>
  <div>
    <v-snackbar
      v-for="(item, index) in errors"
      :key="index"
      v-model="item.show"
      :timeout="0"
      multi-line
      top
      color="error"
    >
      {{ item.text }}
      <v-btn text icon @click.native="hideError(item.id)">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog
      :value="$route.name !== 'login' && auth.showLoginDialog"
      persistent
      max-width="30%"
    >
      <login-dialog @loginSuccess="loginDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import auth from '../../utils/auth'
import { getErrors } from '../../graphql/local-schema/query'
import { hideError } from '../../graphql/local-schema/mutation'
import LoginDialog from './login/LoginDialog.vue'

export default {
  components: { LoginDialog },
  name: 'Errors',
  data() {
    return {
      loginDialog: false,
      auth: auth,
      errors: []
    }
  },
  apollo: {
    errors: {
      query: getErrors,
      update(data) {
        return data.errors
      }
    }
  },
  computed: {
    dialog() {
      return this.loginDialog && this.$route.name !== 'login'
    }
  },
  mounted() {
    if (process.client) {
      setTimeout(() => {
        this.errors.forEach(e => {
          this.hideError(e.id)
        })
      }, 5000)
    }
  },
  methods: {
    hideError(id) {
      this.$apollo.mutate({
        mutation: hideError,
        variables: { id }
      })
    }
  }
}
</script>
