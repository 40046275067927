import gql from 'graphql-tag'

const getErrors = gql`
  {
    errors @client {
      id
      text
      show
    }
  }
`

export { getErrors }
