/* eslint-disable no-console */

export const state = () => ({
  statusOfFirstCheckbox: false, // Состояние чекбокса с которого начали выделение
  isSelected: false // Выделяются ли чекбоксы в данный момент
})

export const mutations = {
  stopSelectFunc: state => (state.isSelected = false),
  startSelectFunc: state => (state.isSelected = true),
  savesStatusOfFirstCheckbox(state, selected) {
    state.statusOfFirstCheckbox = selected
  }
}

export const getters = {}

export const actions = {}
