import auth from '../utils/auth'

export default async ctx => {
  const toPath = ctx.route.path
  if (
    toPath === '/admin/' ||
    toPath === '/admin' ||
    toPath === '/download/'
  ) {
    let fullPath = ctx.route.fullPath
    if (toPath === '/admin') {
      fullPath = ctx.route.fullPath + '/'
    }
    // Редиректим жестко, чтобы не сработали хуки роутера js
    const production = process.env.NODE_ENV === 'production'
    if (production) {
      window.location = fullPath
    } else {
      window.location = 'http://localhost:8000' + fullPath
    }
    return
  }
  if (toPath === '/logout') {
    auth.logout().then(loggedOut => {
      if (loggedOut) {
        ctx.app.apolloProvider.defaultClient.resetStore()
      }
      window.location = '/login'
    })
    return
  }
  const loggedIn = await auth.loggedIn()
  if (loggedIn) {
    if (toPath === '/login' && loggedIn) {
      ctx.redirect('/')
    }
  } else if (toPath !== '/login' && !loggedIn) {
    if (process.client) {
      ctx.next({
        path: '/login',
        query: { redirect: ctx.route.fullPath }
      })
    } else {
      ctx.redirect('/login')
    }
  }
}
